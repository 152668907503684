import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const OmR1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="383.8px"
        height="1090px"
        viewBox="0 0 383.8 1090"
      >
        <GrainPattern id={id} x="100%" />
        <path
          fill={`url(#${id})`}
          d="M383.8,0c-19.2,12.5-38,25.9-55,41.8c-33.1,31-55.6,68.5-78.6,105.3c-26.4,42.3-55.3,78.3-94.2,112
	c-35.4,30.7-73,59.3-103.8,94.7C6,407.5-11,469.6,7.1,521.3c9.9,28.2,28.5,51,49.7,70.5c37.2,44.3,34.4,111.7,76,152.7
	c27.8,27.4,62.5,47,86.4,78.3c22.5,29.4,38,64,53,98.5c26.8,61.4,52.4,126.5,107.3,165.8c1.4,1,2.8,1.9,4.2,2.9V0z"
        />
      </svg>
    )
  }
)

export default OmR1
