import React, { FunctionComponent } from "react"
import styles from "./image-text-row.module.css"
import cx from "classnames"

const ImageTextRow: FunctionComponent<{ rev?: boolean }> = ({
  children,
  rev,
}) => {
  return (
    <section className={cx({ [styles.rev]: rev }, styles.row, "container")}>
      {children}
    </section>
  )
}

export default ImageTextRow
