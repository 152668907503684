import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const OmL1 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="322.2px"
        height="994.3px"
        viewBox="0 0 322.2 994.3"
      >
        <GrainPattern id={id} />
        <path
          fill={`url(#${id})`}
          d="M212.8,136.9c-47.9-36.8-101.4-65.5-153.1-96.8C38.4,27.2,18.7,13.9,0,0v994.3c30-9.7,57.5-21.8,78.3-40.6
	c50.8-46,58.7-118.3,69.3-186.7c5.9-38.4,12.3-77,28.6-110.3c17.4-35.5,51.2-58.8,74.4-90.3c34.8-47.1,8.7-120.3,37.2-170.6
	c18.1-22.4,32.2-48.3,34.2-79.6C325.7,258.9,284.9,192.4,212.8,136.9z"
        />
      </svg>
    )
  }
)

export default OmL1
