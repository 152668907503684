import React, { forwardRef, ComponentPropsWithoutRef } from "react"
import GrainPattern from "./grain-pattern"
import uuidv4 from "uuid/v4"

const OmR2 = forwardRef<SVGSVGElement, ComponentPropsWithoutRef<"svg">>(
  (props, ref) => {
    const id = uuidv4()

    return (
      <svg
        ref={ref}
        {...props}
        width="142.5px"
        height="488.3px"
        viewBox="0 0 142.5 488.3"
      >
        <GrainPattern id={id} />
        <path
          fill={`url(#${id})`}
          d="M142.5,0c-10.2,19.9-17.8,34.7-21.8,42.4c-2.4,19-14.5,48-82.8,168.6c-2.1,4.7-4.2,7.2-5.7,10.4
	c-3,6.3-4.9,12.3-7.3,17.1c-9,18-14.4,26.7-14.4,26.7s-5.4,1.3-6.3,25c-0.3,7.4-3.7,22.4-4.2,34.1c-1.5,33.6,6.9,61,19.2,82.3
	c9.3,4.1,15.7,13.9,23,19.5c8.2,10,17.2,19.2,27,27.6c0.8,0.7,8.6,7.7,9.2,8.2c10.8,8.8,13,7.4,19.2,8.2c4.6,2.1,12.2,5.2,16.6,7.3
	c4.9,2.4,12.4,2.4,17,4.9c3.7,2,7.4,4,11,6.1V0z"
        />
      </svg>
    )
  }
)

export default OmR2
