import React, { FunctionComponent, ReactChildren } from "react"
import styles from "./om-yalla-rinkeby.module.css"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import { graphql } from "gatsby"
import Image, { FluidObject } from "gatsby-image"
import cx from "classnames"
import ImageTextRow from "../components/image-text-row"
import OmL1 from "../components/blobs/om-l-1"
import OmR1 from "../components/blobs/om-r-1"
import OmR2 from "../components/blobs/om-r-2"
import useParallax, { makeTransformY } from "../hooks/use-parallax"
import { OmYallaQuery } from "../generated/graphql"
import { oc } from "ts-optchain"
import renderAst from "../lib/render-ast"

const YallKafePage: FunctionComponent<{ data: OmYallaQuery }> = ({
  data: { page },
}) => {
  const blobRefL1 = useParallax<SVGSVGElement>(null, makeTransformY(1))
  const blobRefR1 = useParallax<SVGSVGElement>(null, makeTransformY(0.7))
  const blobRefR2 = useParallax<SVGSVGElement>(null, makeTransformY(0.2))

  return (
    <Layout>
      <SEO title={oc(page).title("")} />
      <Hero backgroundColor="purple">
        <h1>{oc(page).heroTitle()}</h1>
        {renderAst(oc(page).heroText.childMarkdownRemark.htmlAst())}
      </Hero>
      <OmL1 className={styles.blobL1} ref={blobRefL1} />
      <OmR1 className={styles.blobR1} ref={blobRefR1} />
      <OmR2 className={styles.blobR2} ref={blobRefR2} />
      <div className={styles.main}>
        {oc(page)
          .textAndImageSections([])
          .map((node, i) => {
            if (!node) {
              return null
            }

            return (
              <ImageTextRow rev={i % 2 !== 0}>
                <div>
                  {oc(node).title() ? <h2>{oc(node).title()}</h2> : null}
                  {renderAst(oc(node).text.childMarkdownRemark.htmlAst())}
                </div>
                {oc(node).image.localFile.childImageSharp.fluid() ? (
                  <Image
                    className={styles.image}
                    fluid={
                      oc(
                        node
                      ).image.localFile.childImageSharp.fluid() as FluidObject
                    }
                    alt=""
                  />
                ) : null}
              </ImageTextRow>
            )
          })}
        <h2 className={styles.sectionTitle}>{oc(page).titleSponsors()}</h2>
        {renderAst(oc(page).subtitleSponsors.childMarkdownRemark.htmlAst(), {
          p: ({ children }: { children: ReactChildren }) => (
            <p className={styles.sectionIntro}>{children}</p>
          ),
        })}
        <div className={cx("container", styles.sponsorGrid)}>
          {oc(page)
            .sponsorGroups([])
            .map((sponsor, i) => {
              if (!sponsor) {
                return null
              }

              return (
                <div key={i}>
                  <h3>{oc(sponsor).title()}</h3>
                  {oc(sponsor)
                    .friends([])
                    .map((item, i) => {
                      if (!item) {
                        return null
                      }

                      return (
                        <p key={i}>
                          <span className={styles.sponsorLink}>
                            {item.name}
                          </span>
                        </p>
                      )
                    })}
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query OmYalla($id: String!) {
    page: contentfulOmYalla(id: { eq: $id }) {
      title
      heroTitle
      heroText {
        childMarkdownRemark {
          htmlAst
        }
      }
      textAndImageSections {
        text {
          childMarkdownRemark {
            htmlAst
          }
        }
        title
        image {
          localFile {
            childImageSharp {
              fluid(maxWidth: 565) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      titleSponsors
      subtitleSponsors {
        childMarkdownRemark {
          htmlAst
        }
      }
      sponsorGroups {
        title
        friends {
          name
        }
      }
    }
  }
`

export default YallKafePage
